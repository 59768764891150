<template>
  <div class="serveBindingManage">
    <ds-header title="关键词管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="所属项目：">
        <a-select
          style="width: 200px"
          placeholder="请选择项目"
          v-model="searchForm.projectId"
        >
          <a-select-option v-for="item in projectName" :key="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="词组名称：">
        <a-input v-model.trim="searchForm.keyWord" placeholder="请输入词组名称" class="inputClass" />
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="addKeyWordGroup()"> <a-icon type="plus" /> 新增关键词组 </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
      >
        <span slot="iconUrl" slot-scope="text, record" class="action">
          <div class="imgContainer">
            <img :src="record.iconUrl" v-if="record.iconUrl"/>
          </div>
        </span>
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="view(record)"> 查看 </a-button>
          <a-button class="btnClass" type="link" @click="edit(record)"> 编辑 </a-button>
          <a-button class="btnClass" type="link" @click="restart(record)">
            <span v-if="record.status == 0">启用</span>
            <span v-else>禁用</span>
          </a-button>
          <a-button class="btnClass" type="link" @click="deleteKeyWordGroup(record)"> 删除 </a-button>
          <a-button class="btnClass" type="link" @click="addKeyWord(record)"> 添加关键词 </a-button>
        </span>
      </a-table>
    </div>
    <div>
      <a-modal
        title="添加关键词"
        :visible="visible"
        :footer="null"
        :width="1000"
        :maskClosable="false"
        :closable="false"
        :destroyOnClose='true'
      >
        <a-form-model ref="ruleForm" :model="keyWordForm" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item ref="keyWord" label="关键词名称" prop="keyWord">
            <a-input
              v-model="keyWordForm.keyWord"
              @blur="
                () => {
                  $refs.keyWord.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item ref="content" label="详情介绍" prop="content">
            <div class="content">
              <div id="editer"></div>
            </div>
          </a-form-model-item>
          <a-form-model-item ref="imageUrl" label="关键词图片" prop="imageUrl">
            <a-upload
              name="file"
              list-type="picture-card"
              :action="IMG_API + '/oss/files'"
              class="avatar-uploader"
              :file-list="imgList"
              @change="handleChangeImg"
              :showUploadList="{ showPreviewIcon: false, showRemoveIcon: true }"
              :before-upload="imgBeforeUpload"
            >
              <div v-if="imgList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <p class="tip"><span style="color: red;margin-right: 5px">*</span>请上传尺寸2:3，格式为PNG、JPG的图片</p>
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset: 5 }">
            <a-button type="primary" @click="onSubmit">
              保存
            </a-button>
            <a-button style="margin-left: 10px;" @click="cancel">
              取消
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
    <div>
      <a-modal
        title="添加关键词组"
        :visible="isShowKeyWordGroupForm"
        :footer="null"
        :maskClosable="false"
        :closable="false"
      >
        <a-form-model ref="KeyWordGroupRuleForm" :model="keyWordGroupForm" :rules="keyWordRules" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item ref="projectIds" label="所属项目" prop="projectIds">
            <a-select mode="multiple" v-model="keyWordGroupForm.projectIds" style="width: 100%" placeholder="请选择所属项目">
              <a-select-option v-for="item in projectName" :key="item.id">
                  {{ item.project_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="keyWordGroupName" label="关键词组名称" prop="keyWordGroupName">
            <a-input
              v-model="keyWordGroupForm.keyWordGroupName"
              @blur="
                () => {
                  $refs.keyWordGroupName.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item ref="status" label="状态" prop="status" :required="true">
            <a-radio-group v-model="keyWordGroupForm.status">
              <a-radio :value="1">
                启用
              </a-radio>
              <a-radio :value="0">
                禁用
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset: 5 }">
            <a-button type="primary" @click="onSubmitKeyWordGroup">
              保存
            </a-button>
            <a-button style="margin-left: 10px;" @click="cancelKeyWordGroupForm">
              取消
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { getKeyWordList, insertKeyWordGroup, insertKeyWord,deleteKeyWord, restartKeyWord } from "@/api/yearEndActivities";
import wangEditor from "wangeditor";
import { projectName } from '@/api/modularManagement'
import { IMG_API } from "@/config/index";
import moment from 'moment/moment';
export default {
  name: "keyWord",
  components: {

  },
  data() {
    return {
      visible: false,
      previewVisible: false,
      isShowKeyWordGroupForm: false,
      imgList: [],
      // 关键词
      keyWordForm: {
        keywordGroupId: '',
        keyWord: '',
        content: '',
        imageUrl: ''
      },
      // 关键词组
      keyWordGroupForm: {
        id: '',
        projectIds: [],
        keyWordGroupName: '',
        status: 0
      },
      IMG_API: IMG_API,
      labelCol: { span: 7 },
      wrapperCol: { span: 14 },
      searchForm: {
        keyWord: '',
        projectId: undefined,
      },
      // 项目名称
      projectName: [],
      table: {
        dataInfo: [],
        columns: [
          {
            title: "所属项目",
            dataIndex: "projectName",
            key: "projectName",
          },
          {
            title: "词组名称",
            dataIndex: "keyWordGroupName",
            key: "keywordGroupName",
            scopedSlots: { customRender: "keywordGroupName" },
          },
          {
            title: "创建时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
            customRender(text) {
              return moment(text).format('YYYY-MM-DD HH:mm:ss');
            },
          },
          {
            title: "状态",
            dataIndex: "status",
            key: "status",
            customRender(text) {
              return text === 1 ? "启用" : "禁用";
            },
          },
          {
            title: "操作",
            key: "action",
            // width: "240px",
            scopedSlots: { customRender: "action" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      rules: {
        keyWord: [
          {required: true, message: '请输入关键词名称！', trigger: 'blur'},
          {max: 6, message: '关键词名称不能超过6个字符！', trigger: 'blur'}
        ],
        content: [
          {required: true, message: '请输入详情介绍！', trigger: 'blur'}
        ],
        imageUrl:[
          {required: true, message: '请上传关键词图片！', trigger: 'change'}
        ]
      },
      keyWordRules: {
        projectIds: [
          {required: true, message: '请选择所属项目！', trigger: 'change'}
        ],
        keyWordGroupName: [
          {required: true, message: '请输入关键词组名称！', trigger: 'blur'}
        ]
      }
    };
  },
  mounted() {

  },
  created() {
    this.searchFormList();
    this.projectNameList();
  },
  methods: {
    // 搜索
    search() {
      this.table.pagination.current = 1;
      this.searchFormList();
    },

    // 重制
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm = this.$options.data.call(this).searchForm;
      this.searchFormList();
    },
    // 保存关键词添加
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          insertKeyWord(this.keyWordForm).then(res => {
            if (res.code === '200') {
              this.$message.success("操作成功！")
              this.visible = false
              this.searchFormList()
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 取消
    cancel() {
      this.visible = false
    },
    // 新增关键词组
    onSubmitKeyWordGroup() {
      let data = JSON.parse(JSON.stringify(this.keyWordGroupForm))
      this.$refs.KeyWordGroupRuleForm.validate(valid => {
        if (valid) {
          data.projectNames = this.getSelectedProjectNames(data.projectIds)
          data.projectIds = data.projectIds.toString()
          insertKeyWordGroup(data).then(res => {
            if (res.code === '200') {
              this.$message.success("操作成功！")
              this.isShowKeyWordGroupForm = false
              this.searchFormList()
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 获取项目名称
    getSelectedProjectNames(data) {
      let projects =  data.map(item => {
        return this.projectName.find(arr => arr.id === item)
      })
      return projects.map(item => {
        return item.project_name
      }).toString()
    },
    cancelKeyWordGroupForm () {
      this.isShowKeyWordGroupForm = false
    },

    // 添加关键词
    addKeyWord(record) {
      this.resetKeyWordForm()
      this.imgList = []
      this.visible = true
      this.keyWordForm.keywordGroupId = record.id
      setTimeout(()=> {
        this.$refs.ruleForm.resetFields();
        this.createEditor()
      },300)
    },
    // 重置关键词form
    resetKeyWordForm() {
      this.keyWordForm.keyWord = ''
      this.keyWordForm.content = ''
      this.keyWordForm.imageUrl = ''
    },
    // 上传图片
    handleChangeImg({ fileList }) {
      this.imgList = fileList;
      if(this.imgList.length > 0 && fileList[0].status === 'done') {
        this.keyWordForm.imageUrl = this.imgList[0].response.redirect_uri
      } else {
        this.keyWordForm.imageUrl = ''
      }
    },
    //上传文件之前校验图片大小
    imgBeforeUpload(file) {
      const that = this
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      const isJPG = type === "png" || type === "jpg";
      if (!isJPG) {
        this.$message.error("上传图片只能是jpg、png格式!");
        return new Promise(function (resolve, reject) {return reject});
      }
      if (file.size/1024 > 500) {
        this.$message.error("上传图片不能大于500KB!");
        return new Promise(function (resolve, reject) {return reject});
      }
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const proportion = that.reductionTo(img.width, img.height);
          const valid = proportion[0] === 2 && proportion[1] === 3
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          that.$message.error("图片不规范，请按要求上传");
          return reject();
        }
      );
      return isJPG && isSize;
    },

    //计算图片比例
    reductionTo(m, n) {
      let arr = [];
      let a = m;
      let b = n;
      (a >= b) ? (a = m, b = n) : (a = n, b = m);
      if (m != 1 && n != 1) {
        for (let i = b; i >= 2; i--) {
          if (m % i == 0 && n % i == 0) {
            m = m / i;
            n = n / i;
          }
        }
      }
      arr[0] = m;
      arr[1] = n;
      return arr;
    },

    // 新增关键词组
    addKeyWordGroup () {
      this.resetKeyWordGroupForm()
      this.isShowKeyWordGroupForm = true
      if (this.$refs.KeyWordGroupRuleForm !== undefined){
        this.$refs.KeyWordGroupRuleForm.resetFields();
      }
    },
    // 初始化关键词组form
    resetKeyWordGroupForm() {
      this.keyWordGroupForm.keyWordGroupName = ''
      this.keyWordGroupForm.projectIds = []
      this.keyWordGroupForm.status = 0
    },
    // 禁用启用
    restart(data) {
      let newData = {
        id: data.id,
        status: data.status === 1 ? 0 : 1
      }
      const that = this
      this.$confirm({
        title: `确定要${data.status === 1 ? '禁用' : '启用'}该条数据吗？`,
        onOk() {
          restartKeyWord(newData).then(res => {
            if (res.code === '200') {
              that.$message.success("操作成功！")
              that.searchFormList()
            }
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    // 编辑
    edit(data) {
      this.$router.push({
        path: '/yearEndActivities/keyWordManagement/view',
        query: {
          id: data.id,
          type: 'edit'
        }
      })
    },
    // 查看
    view(data) {
      this.$router.push({
        path: '/yearEndActivities/keyWordManagement/view',
        query: {
          id: data.id,
          type: 'view'
        }
      })
    },
    // 删除
    deleteKeyWordGroup(data) {
      const that = this
      this.$confirm({
        title: '确定要删除该条数据吗?',
        onOk() {
          deleteKeyWord(data.id).then(res => {
            if (res.code === '200') {
              that.$message.success("操作成功！")
              that.searchFormList()
            }
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    // 列表
    async searchFormList() {
      let data = {};
      data.keyword = this.searchForm.keyWord
      data.projectId = this.searchForm.projectId ? this.searchForm.projectId : ''
      data.page = this.table.pagination.current;
      data.size = this.table.pagination.pageSize
      const res = await getKeyWordList(data);
      if (res.code === '200') {
        this.table.dataInfo = res.data.list;
        this.table.pagination.total = res.data.count;
      }
    },

    // 项目列表
    async projectNameList() {
      const res = await projectName();
      if (res.code === '200') {
        this.projectName = res.data.items;
      }
    },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchFormList();
    },
    createEditor() {
      const _this = this;
      const editor = new wangEditor(`#editer`);
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.keyWordForm.content = newHtml;
      };
      // const editorConfig = {                       // JS 语法
      //   MENU_CONF: {}
      //
      //   // 其他属性...
      // }
      editor.config.menus = [
        "fontName",
        "fontSize",
        "foreColor",
        "backColor",
        "underline",
        "italic",
        "bold",
        "justify",
        "splitLine",
        "undo",
        "redo",
        "list",
        "colors"
      ];
      editor.config.colors = [
        '#3fabff',
        '#9d9452',
        '#76849e',
        '#e3edff',
        '#3c3e02',
        '#011b7c',
        '#3e1602',
        '#ffffff',
        '#000000',
      ]
      editor.config.uploadVideoServer = this.IMG_API + "/oss/files";
      editor.config.uploadImgServer = this.IMG_API + "/oss/files";
      editor.config.uploadImgShowBase64 = false;
      editor.config.showLinkImg = false;
      editor.config.showLinkVideo = false;
      editor.config.uploadVideoMaxSize = 5 * 1024 * 1024;
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
      editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
      editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图
      // editor.config.uploadVideoAccept = ['mp4']
      editor.config.uploadFileName = "file";
      editor.config.uploadVideoName = "file";
      editor.config.uploadVideoTimeout = 60000;
      editor.config.uploadImgTimeout = 60000;
      editor.config.uploadVideoHooks = {
        // 上传视频之前
        before: function (xhr, editor, files) {
          console.log(xhr);
          console.log(editor);
          console.log(files);
        },
        // 视频上传并返回了结果，视频插入已成功
        success: function (xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频超时
        timeout: function (xhr) {
          _this.$message.error("上传请求超时");
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function (insertVideoFn, result) {
          let videoHTML =
            '&nbsp;<video poster="' +
            result.redirect_uri +
            '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' +
            result.redirect_uri +
            '" controls style="max-width:100%"></video>&nbsp;';
          editor.cmd.do("insertHTML", videoHTML);
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          // insertVideoFn(result.redirect_uri)
        },
      };
      editor.config.uploadImgHooks = {
        // 上传视频之前
        before: function (xhr) {},
        // 视频上传并返回了结果，视频插入已成功
        success: function (xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频超时
        timeout: function (xhr) {
          _this.$message.error("上传请求超时");
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function (insertVideoFn, result) {
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          insertVideoFn(result.redirect_uri);
        },
      };
      editor.config.customAlert = function (s, t) {
        switch (t) {
          case "success":
            _this.$message.success(s);
            break;
          case "info":
            _this.$message.info(s);
            break;
          case "warning":
            _this.$message.warning(s);
            break;
          case "error":
            _this.$message.error(s);
            break;
          default:
            _this.$message.info(s);
            break;
        }
      };
      editor.config.placeholder = "请输入";
      // 创建编辑器
      editor.create();
      this.editor = editor;
      setTimeout(() => {
        this.editor.txt.html(this.keyWordForm.content)
      },500);
      // 查看的时候禁用富文本
      // if (this.$route.query.type === "view") {
      //   this.editor.disable();
      // } else {
      //   this.editor.enable();
      // }
    }
  },
};
</script>

<style lang="scss" scoped>
.btnClass {
  padding: 0px;
  padding-right: 5px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.inputClass {
  width: 200px;
}
.imgContainer {
  width: 80px;
  height: 80px;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
